exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bookmarks-index-tsx": () => import("./../../../src/pages/bookmarks/index.tsx" /* webpackChunkName: "component---src-pages-bookmarks-index-tsx" */),
  "component---src-pages-bookmarks-markdown-remark-fields-slug-tsx": () => import("./../../../src/pages/bookmarks/{markdownRemark.fields__slug}.tsx" /* webpackChunkName: "component---src-pages-bookmarks-markdown-remark-fields-slug-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-notes-index-tsx": () => import("./../../../src/pages/notes/index.tsx" /* webpackChunkName: "component---src-pages-notes-index-tsx" */),
  "component---src-pages-notes-markdown-remark-fields-slug-tsx": () => import("./../../../src/pages/notes/{markdownRemark.fields__slug}.tsx" /* webpackChunkName: "component---src-pages-notes-markdown-remark-fields-slug-tsx" */),
  "component---src-pages-resume-index-tsx": () => import("./../../../src/pages/resume/index.tsx" /* webpackChunkName: "component---src-pages-resume-index-tsx" */),
  "component---src-pages-sheets-index-tsx": () => import("./../../../src/pages/sheets/index.tsx" /* webpackChunkName: "component---src-pages-sheets-index-tsx" */),
  "component---src-pages-sheets-markdown-remark-fields-slug-tsx": () => import("./../../../src/pages/sheets/{markdownRemark.fields__slug}.tsx" /* webpackChunkName: "component---src-pages-sheets-markdown-remark-fields-slug-tsx" */)
}

